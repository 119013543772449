.grid {
  margin-bottom: 1rem;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Убираем маржин для браузера Safari */
  }

  & > div {
    padding-top: 0 !important;
  }

}

.title {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1rem;
}

.submit {
  padding: 0.5rem 1rem !important;

  & p {
    display: flex;
    margin: 0;
    line-height: normal;
  }
}

.buffer {
  margin-bottom: 1rem;
  &__items {
    display: flex;
    align-items: center;

    & > div:not(:last-child) {
      margin-right: 0.5rem;
    }

    & .buffer__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      & h6 {
        margin: 0;
      }

    & p {
      line-height: normal;
      margin: 0;
    }
    }

  }

  & h4 {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.3rem;
  }
}
