.wrapper {
  padding: 1rem;

  & ul {
    padding: 0;
    margin: 0;

    & li {
      list-style: none;
      display: flex;
      align-items: center;
    }

    & button {
      padding: 0.5rem;

      & p {
        margin: 0 0 0 0.5rem;
        padding: 0;
        line-height: normal;
      }
    }
  }
}

.wrapper__list {
  margin-top: 1rem;
  background-color: #ffffff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: black;
}
